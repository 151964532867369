import React, { useEffect } from "react";
import {
  createContext,
  useState,
  useMemo,
  useCallback,
  useContext,
} from "react";
import { WordContext } from "./WordContext";

export const LangContext = createContext(); //Create a context for language-related data

function LangContextProvider(props) { //Define a context provider component
  const { word } = useContext(WordContext); //Access the "word" from WordContext
  const [lang1Library, setLang1Library] = useState([]); //Create state for the language library
  const [lang1Pockets, setLang1Pockets] = useState([]); //Create state for the language pockets

  const [lang1LibraryFilter, setLang1LibraryFilter] = useState(""); //Create state for the library filter
  const [unfilteredLang1Library, setUnfilteredLang1Library] = useState([]); //Create state for the unfiltered library

  // BOF : Utility function to print the library content
  const listItems = (list1, name) => {
    if (name === "lang1Library" || name === "unfilteredLang1Library") { //Check if the list is a library
      if (list1) { //If list is not empty, iterate over the list
        for (let index = 0; index < list1.length; index++) {
          const element = list1[index];
          console.info(` ${name}[${index}] = ${element.lang_word}`); //Log each element
        }
      } else {
        console.info(`List ${name} is empty !`); //Log if the list is empty
      }
    } else if (name === "lang1Pockets") { //Check if the list is a pocket
      if (list1) {
        console.log("Start of lang1Pockets");
        for (let key in list1) { //Iterate over the pockets
          const pocketList = list1[key];
          let pocketString = "";
          for (let index = 0; index < pocketList.length; index++) { //Iterate over the pocket list
            const element = pocketList[index];
            pocketString = pocketString + "{ ";
            for (let prop in element) { //Iterate over properties of pocket item
              pocketString = pocketString + ", " + prop + ": " + element[prop];
            }
            pocketString = pocketString + " }";
          }
          console.log(`${key} : ${pocketString}`); //Log the pocket content
        }
        console.log("End of lang1Pockets");
      } else {
        console.info(`List ${name} is empty !`); //Log if the list is empty
      }
    }
  };
  // EOF : Utility function to print the library content

  useEffect(() => {
    setLang1LibraryFilter(""); // if word is changed, reset the lang1Libraryfilter
  }, [word]);

  useEffect(() => {
    console.log("Filter has changed ..");
    //listItems(unfilteredLang1Library, "unfilteredLang1Library");
  }, [lang1LibraryFilter]);

  useEffect(() => {
    console.log("UnfilteredLang1Library has changed..");
    //listItems(unfilteredLang1Library, "unfilteredLang1Library");
    updateLang1Library();
  }, [lang1LibraryFilter, unfilteredLang1Library]);

  useEffect(() => {
    console.log("Lang1Library has changed..");
    //listItems(lang1Library, "lang1Library");
  }, [lang1Library]);

  const updateUnfilteredLang1Library = useCallback(
    // in case there is a lang1
    (newSet) => { //Function to update the unfiltered library
      console.log("Updating unfilteredLang1Library content..");
      //even if the set is empty, we need to set it empty and make it current(actual)
      //if (newSet?.length !== 0) { //If the new set is not empty
        setUnfilteredLang1Library(newSet); //Update the unfiltered library state
      //}
    },
    [setUnfilteredLang1Library], //Dependencies for the callback
  );

  const updateLang1Library = useCallback(() => { //Memoize the function
    let filtered = [];
    if (unfilteredLang1Library?.length !== 0) { //If the unfiltered library is not empty
      filtered = unfilteredLang1Library.filter((item) => //Filter the unfiltered library
        item.lang_word.toLowerCase().includes(lang1LibraryFilter.toLowerCase()), //Filter based on the filter string
      );
      setLang1Library(filtered); //Update the filtered libary state
    } else {
      setLang1Library([]); //Set filtered library to empty if the unfiltered library is empty
    }
  }, [unfilteredLang1Library, lang1LibraryFilter, setLang1Library]); //Dependencies for the callback

  const updateLang1LibraryFilter = useCallback( //Memoize the function
    (filterString) => { //Function to update the library filter string
      setLang1LibraryFilter(filterString); //Update the filter state
      console.log("Setting library filter key : " + filterString);
    },
    [setLang1Library], //Dependencies for the callback
  );

  const updateLang1Pockets = useCallback( //Memoize the function
    (newSet) => { //Function to update language pockets
      // remove empty arrays from pocketLists

      for (let pocketId in newSet) {
        const element = newSet[pocketId];
        if (element.length === 0) {
          delete newSet[pocketId];
        }
      }

      //listItems(newSet, "lang1Pockets");
      setLang1Pockets(newSet); //Update the pocket state
    },
    [setLang1Pockets], //Dependencies for the callback
  );

  const initLang1Pockets = useCallback( //Memoize the function
    (data) => { //Function to initialize the language pockets
      let lists = {};
      const pockets = data.pockets || [];

      for (const item of pockets) {
        let chapter_id = item.chapter_id;
        let meaning_id = item.meaning_id;
        let pocket_id = chapter_id + "-" + meaning_id;
        let meaningLangs = pockets.filter(
          (tmp) =>
            tmp.chapter_id === chapter_id && tmp.meaning_id === meaning_id,
        );
        lists[pocket_id] = meaningLangs; //Populate lists with pocket data
      }

      //console.log("Initializing Lang1Pockets");
      setLang1Pockets(lists); //Update the pockets state
    },
    [setLang1Pockets], //Dependencies for the callback
  );

  const value = useMemo( //Memoize the context value
    () => ({
      lang1Library, //Provide the library state
      updateLang1Library, //Provide the function to update the library

      lang1LibraryFilter, //Provide the library filter state
      updateLang1LibraryFilter, //Provide the function to update library filter

      unfilteredLang1Library, //Provide the unfiltered library state
      updateUnfilteredLang1Library, //Provide the function to update the unfiltered library

      lang1Pockets, //Provide the pockets state
      initLang1Pockets, //Provide the function to initialize pockets
      updateLang1Pockets, //Provide the function to update pockets
    }),
    [lang1LibraryFilter, lang1Pockets, lang1Library], //Dependencies for the memoization
  );

  return ( //Return the context provider with the memoized value
    <LangContext.Provider value={value}>{props.children}</LangContext.Provider>
  );
}
export default LangContextProvider; //Export the provider component as the default export
