import correctSortOrders from "./CorrectSortOrders";

//Handles the logic for when an item within the same pocket is repositioned through drag-and-drop interaction
export default function DragEndTypeChangeOrderInPocket(
  result,
  lang1Pockets,
  updateLang1Pockets,
) {
  const { source, destination } = result;
  console.log("TYPE - 3 - Order In the same pocket has changed");
  // dropped within the same language container -> order has changed
  const column = lang1Pockets[source.droppableId]; // Retrieve the array of items from the source pocket
  const copiedItems = [...column]; // Make a copy of the array to work with without modifying the original directly
  const [removed] = copiedItems.splice(source.index, 1); // Remove the item being dragged from its original position
  copiedItems.splice(destination.index, 0, removed); // Insert the removed item into its new position based on the destination index

  //reassign sort order data after reschuffle
  const newPockets = correctSortOrders(copiedItems);

  // Prepare the updated pockets object with the modified pocket
  let tmpPockets = {
    ...lang1Pockets,
    [source.droppableId]: newPockets,
  };

  updateLang1Pockets(tmpPockets); // Call the provided function to update the state with the new pockets data
}
