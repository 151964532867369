import React, { Component } from "react";

/* 
This component displays different types of words based on the props it receives, 
categorizing them into primary types (isim, fiil, sıfat, zarf) and area types, 
rendering them in a flexible row layout
*/
class WordTypeComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: props,
    };
  }
  render() {
    // Define primary word types
    const PRIMARY_TYPES = ["isim", "fiil", "sıfat", "zarf"];
    // Check if props are undefined or null, render nothing if true
    if (
      typeof this.state.values.props === "undefined" ||
      this.state.values.props === null
    ) {
      return <div></div>;
    } else {
      let wordTypeArray = this.state.values.props.split(","); // Split props into an array of word types
      const primaryMap = [];
      const areaMap = [];
      let keyCount = 0;
      // Iterate through wordTypeArray
      wordTypeArray.forEach((element) => {
        let clearText = element.replaceAll("#-", ""); // Remove specific characters
        clearText = clearText.trim(); // Trim whitespace
        // Check if the word type is in PRIMARY_TYPES
        if (PRIMARY_TYPES.includes(clearText)) {
          //console.log('Pushing to primary:' + clearText);
          primaryMap.push({ key: keyCount, area: clearText, text: clearText }); // Add to primaryMap if it's a primary type
          keyCount++;
        } else { // Otherwise, add to areaMap if it's not empty
          //console.log('Pushing to area:' + clearText);
          if (!("" === clearText)) {
            areaMap.push({ key: keyCount, area: "area-type", text: clearText });
            keyCount++;
          }
        }
      });

      // Render the word type components
      return (
        <div className="flex-row">
          {primaryMap.map((item) => (
            <div key={item.key} className={`word-type ${item.area}`}>
              {item.text}
            </div>
          ))}
          {areaMap.map((item) => (
            <div key={item.key} className={`word-type ${item.area}`}>
              {item.text}
            </div>
          ))}
        </div>
      );
    }
  }
}

export default WordTypeComp;
