import HistoryIconsFct from "./HistoryIconsFct";
import axios from "axios"; // Import Axios
import HistoryIcon from "@mui/icons-material/History";
import React, { useState, useEffect, useContext } from "react";
import { WordContext } from "../Contexts/WordContext";
import Foldable from "../Common/Foldable";
import ErrorMessage from "../Common/ErrorMessage";
import LoadingIndicator from "../Common/LoadingIndicator";
import { useTranslation } from 'react-i18next';

function HistoryContainerFct(URL, title) {
  const { t, i18n } = useTranslation();
  const { word, setWord } = useContext(WordContext); // Accessing word and setWord from WordContext
  const [loading, setLoading] = useState(true); // State for loading status
  const [error, setError] = useState(null); // State for error handling
  const [data, setData] = useState([]); // State for storing fetched data

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(URL); // Fetching data from the provided URL using Axios
        setData(response.data); // Setting fetched data to state
        setLoading(false); // Setting loading state to false once data is fetched
      } catch (error) {
        setError(error); // Handling and setting error state if request fails
        setLoading(false); // Setting loading state to false on error
      }
    };

    fetchData(); // Calling fetchData function on component mount

    // Cleanup function (optional)
    return () => {
      // Cancel pending requests, cleanup timers, etc.
    };
  }, [URL, word]); // Dependencies include URL and word from context

  if (loading)
    return (
      <Foldable title={title} ComponentIcon={<HistoryIcon />}>
        <LoadingIndicator loading={loading} />
      </Foldable>
    );

  if (error !== null) {
    console.log("error:" + error);
  }

  if (error) {
    console.log("error:" + error.message + error.stack);
     
    return (
      <Foldable title={title} ComponentIcon={<HistoryIcon />}>
        <ErrorMessage message={t("history.error")} />
        </Foldable>
      );
  }

  // Function to calculate scale based on maximum count in history data
  function calculateScale() {
    let histArray = data;

    let maxCount = 0;
    for (let item of histArray) {
      if (item.count > maxCount) {
        maxCount = item.count;
      }
    }

    let scale = maxCount / 5.0;
    if (scale > 1) {
      scale = 1 / scale;
    }
    return scale;
  }

  let infoMsg = <div></div>; // Initializing info message JSX variable
  let historyList = <tr></tr>; // Initializing history list JSX variable

  // Conditionally rendering based on data availability
  if (data === null || data.length === 0) {
    infoMsg = <div>{t("history.noEntries")}</div>; // Message when no data entries are present
  } else if (data !== null && data.length > 0) {
    let scale = calculateScale(); // Calculating scale based on data

    // Mapping through data to create history list JSX
    historyList = data.map((item) => (
      <tr key={item.id}>
        <td
          style={{
            textAlign: "right",
            width: "55%",
            padding: "0px 20px 0px 0px",
          }}
        >
          <div className="margin-mini mid-font">{item.word}</div>
        </td>
        <td
          style={{
            textAlign: "left",
            width: "45%",
            padding: "0px 0px 0px 20px",
          }}
        >
          <HistoryIconsFct id={item.id} count={item.count} scale={scale} />
        </td>
      </tr>
    ));
  } else {
    infoMsg = <div>{t("history.generalError")}</div>;
  }
  // Returning JSX with title, history list table, and info message
  return (
    <Foldable title={title} ComponentIcon={<HistoryIcon />}>
      <div className="flex-row">
        <table
          className="margin-mid mid-font"
          style={{
            borderWidth: "3px",
            width: "100%",
          }}
        >
          <tbody>{historyList}</tbody>
        </table>
      </div>
      <div className="margin-mid mid-font">{infoMsg}</div>
    </Foldable>
  );
}

export default HistoryContainerFct;
