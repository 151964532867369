import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
i18n
  .use(HttpBackend) // Load translations from files
  .use(LanguageDetector) // Detect user's language
  .use(initReactI18next) // Pass to react-i18next
  .init({
    lng: "tr",
    fallbackLng: "tr", // Default language
    supportedLngs: ["tr", "en"], // List of supported languages
    debug: true,
    interpolation: {
      escapeValue: false, // React already escapes values
    },
    backend: {
      loadPath: "/locales/{{lng}}/translation.json", // Path to translation files
    },
  });
export default i18n;