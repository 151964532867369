import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import MAPIURLMaker from "../MAPIURLMaker";
import AddIcon from "@mui/icons-material/Add"; // For "+"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"; // For arrows
import SearchIcon from "@mui/icons-material/Search"; // For search
import "../../styles/new.css";
import TableChartIcon from "@mui/icons-material/TableChart";
import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SwipeIcon from "@mui/icons-material/Swipe";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import { useSwipeable } from "react-swipeable";
import Foldable from "../Common/Foldable";
import ErrorMessage from "../Common/ErrorMessage";
import NoDataMessage from "../Common/NoDataMessage";
import LoadingIndicator from "../Common/LoadingIndicator";
import { useTranslation } from "react-i18next";

const WordMorphology = ({ word }) => {
  const { t, i18n } = useTranslation();
  const [inputWord, setInputWord] = useState(word || "");
  const [results, setResults] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  const fetchAnalysis = async (searchWord) => {
    try {
      const fetchStr = MAPIURLMaker(`analyze?word=${searchWord}`);
      // Fake loading delay
      //await new Promise(resolve => setTimeout(resolve, 3000));

      const response = await axios.get(fetchStr);

      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return response.data;
    } catch (error) {
      console.error("Fetch error:", error);
      setError(t("morph.Error"));
      //return null;
    }
  };

  const handleSearch = async () => {
    setResults(null); // Clear previous results
    setError(null); // Clear previous errors
    setCurrentWordIndex(0); // Reset index
    setLoading(true);

    const words = inputWord.trim().split(/\s+/); // Split by whitespace
    if (words.length === 0) {
      setError(t("morph.Warning"));
      setLoading(false);
      return;
    }

    try {
      const allAnalyses = [];
      for (const word of words) {
        const data = await fetchAnalysis(word);
        if (data && data.length > 0) {
          allAnalyses.push(data);
        } else {
          allAnalyses.push([]); // Handle words with no analyses
        }
      }
      // Check if all analyses are empty and set an error message accordingly
      if (allAnalyses.every((analysis) => analysis.length === 0)) {
        setError(t("morph.NoData"));
      } else {
        setResults(allAnalyses);
        setError(null);
      }
    } catch (error) {
      setError(t("morph.Error"));
      setResults(null);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setInputWord(event.target.value);
    }
  };

  const memoizedInputWord = useMemo(() => inputWord, [inputWord]);

  const handleNext = () => {
    if (results && currentWordIndex < results.length - 1) {
      setCurrentWordIndex(currentWordIndex + 1);
    }
  };

  const handlePrev = () => {
    if (results && currentWordIndex > 0) {
      setCurrentWordIndex(currentWordIndex - 1);
    }
  };

  useEffect(() => {
    if (word !== memoizedInputWord) {
      setInputWord(word);
    }
  }, [word]);

  useEffect(() => {
    if (memoizedInputWord && memoizedInputWord.trim()) {
      handleSearch();
    }
  }, [memoizedInputWord]);

  const noDataMessage = t("morph.NoData");

  return (
    <Foldable title={t("morph.Title")} ComponentIcon={<TableChartIcon />}>
      <fieldset className="fieldset">
        <div className="legend">{t("search.Text")}</div>
        <div className="flex-row">
          <input
            id="txtWordSearch"
            className="edit-box margin-all"
            type="text"
            value={inputWord}
            onChange={(e) => setInputWord(e.target.value)}
            placeholder={t("search.Placeholder")}
            onKeyDown={handleKeyDown}
          />
          <button
            className="toggle-button flex-row"
            style={{ paddingLeft: "20px" }}
            onClick={handleSearch}
          >
            <div className="margin-mini">{t("search.Button")}</div>
            <SearchIcon fontSize="small" />
          </button>
        </div>
      </fieldset>
      {loading && <LoadingIndicator loading={loading} />}
      {!loading && error && (
        <>
          {error === noDataMessage ? (
            <NoDataMessage message={error} />
          ) : (
            <ErrorMessage message={error} />
          )}
        </>
      )}
      {results && !loading && (
        <Results
          analyses={results}
          currentWordIndex={currentWordIndex}
          handleNext={handleNext}
          handlePrev={handlePrev}
          inputWord={inputWord}
        />
      )}
    </Foldable>
  );
};
/*
const MorphemeBox = ({ text, isTop }) => (
  <div className={`morpheme-box ${isTop ? "top" : "bottom"}`}>{text}</div>
);
*/

const MorphemeBox = ({ text, description, isTop, showDescriptions }) => (
  <Tooltip
    title={description}
    arrow
    classes={{ tooltip: "custom-tooltip" }}
    style={{ cursor: "pointer" }}
    disableHoverListener={showDescriptions}
  >
    <div className={`morpheme-box small-box ${isTop ? "top" : "bottom"} ${showDescriptions ? "show-description" : ""}`}>{text}</div>
  </Tooltip>
);



const PlusSign = () => (
  <div className="plus-sign">
    <AddIcon fontSize="small" />
  </div>
);

const Arrow = () => (
  <div className="arrow">
    <ArrowDownwardIcon fontSize="small" />
  </div>
);

// MorphemesRow component to render morphemes
/*const MorphemesRow = ({ morphemes }) => (
  <div className="table-morph">
    <div className="table-morph-row">
      {morphemes.map((morpheme, index) => (
        <React.Fragment key={index}>
          <div className="table-morph-cell">
            <MorphemeBox text={morpheme} isTop />
          </div>
          {index < morphemes.length - 1 && (
            <div className="table-morph-cell">
              <PlusSign />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  </div>
);*/

const MorphemesRow = ({ morphemes, descriptions, showDescriptions }) => {
  const { t } = useTranslation();

  return (
    <div className="table-morph">
      {/* Morphemes Row */}
      <div className="table-morph-row">
        {morphemes.map((morpheme, index) => (
          <React.Fragment key={index}>
            <div className="table-morph-cell">
              <Tooltip 
                title={
                  !showDescriptions ? (
                    <ul className="description-list">
                      {descriptions[index]
                        .split(" / ")
                        .map((desc, i) => (
                          <li className="description-item" key={i}>{desc}</li>
                        ))}
                    </ul>
                  ) : null
                }
                arrow
                disableHoverListener={showDescriptions}
              >
                <div className="morpheme-box small-box">{morpheme}</div>
              </Tooltip>
            </div>
            {index < morphemes.length - 1 && (
              <div className="table-morph-cell">
                <PlusSign />
              </div>
            )}
          </React.Fragment>
        ))}
        <div className="table-morph-cell">
          <Tooltip title={t("morph.InfoHover")} arrow>
            <InfoIcon className="info-icon" />
          </Tooltip>
        </div>
      </div>

      {/* Arrow Row */}
      {showDescriptions && (
        <div className="table-morph-row">
          {morphemes.map((_, index) => (
            <React.Fragment key={index}>
              <div className="table-morph-cell">
                <Arrow />
              </div>
              {index < morphemes.length - 1 && (
                <div className="table-morph-cell"></div>
              )}
            </React.Fragment>
          ))}
        </div>
      )}

      {/* Descriptions Row */}
      {showDescriptions && (
        <div className="table-morph-row">
          {descriptions.map((description, index) => (
            <React.Fragment key={index}>
              <div className="table-morph-cell">
                <div className="description-box small-box">
                <ul className="description-list">
                  {description.split(" / ").map((desc, i) => (
                    <li className="description-item" key={i}>{desc}</li>
                  ))}
                </ul>
                </div>
              </div>
              {index < descriptions.length - 1 && (
                <div className="table-morph-cell">
                  <PlusSign />
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};




// Updated DescriptionsRow component to render combined descriptions
const DescriptionsRow = ({ descriptions }) => (
  <div className="table-morph">
    <div className="table-morph-row">
      {descriptions.map((descriptionGroup, index) => (
        <React.Fragment key={index}>
          <div className="table-morph-cell">
            <MorphemeBox text={descriptionGroup.join(" / ")} />{" "}
            {/* Combine descriptions with ' / ' */}
          </div>
          {index < descriptions.length - 1 && (
            <div className="table-morph-cell">
              <PlusSign />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  </div>
);

const ArrowsRow = ({ count }) => (
  <div className="table-morph">
    <div className="table-morph-row">
      {Array.from({ length: count }).map((_, index) => (
        <React.Fragment key={index}>
          <div className="table-morph-cell" id="arrow-cell">
            <Arrow />
          </div>
          {index < count - 1 && (
            <div className="table-morph-cell">
              <div className="plus-sign"></div>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  </div>
);

const Results = ({
  analyses,
  currentWordIndex,
  handleNext,
  handlePrev,
  inputWord,
}) => {
  const { t } = useTranslation();
  const [showDescriptions, setShowDescriptions] = useState(false);

  const toggleDescriptions = () => setShowDescriptions(!showDescriptions);

  //console.log("Analyses:", analyses);
  if (!Array.isArray(analyses)) {
    return <NoDataMessage message="No analyses available." />;
  }

  // Check if the first element is an array
  const hasMultipleAnalyses = Array.isArray(analyses[0]);
  //console.log("Current word has multiple analyses?:", hasMultipleAnalyses);

  const words = inputWord.trim().split(/\s+/);
  const isSingleWord = words.length === 1;
  //console.log("Is Single Word:", isSingleWord);

  // If it's a phrase, flatten the results
  const displayedAnalyses = hasMultipleAnalyses
    ? analyses[currentWordIndex]
    : analyses;

  //console.log("Displayed Analyses:", displayedAnalyses);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (hasMultipleAnalyses && currentWordIndex < analyses.length - 1)
        handleNext();
    },
    onSwipedRight: () => {
      if (hasMultipleAnalyses && currentWordIndex > 0) handlePrev();
    },
    trackMouse: true,
  });

  return (
    <div className="results-box" {...handlers}>
      
      <div className="description-toggle legend">
        <label>
          <input
            type="checkbox"
            checked={showDescriptions}
            onChange={toggleDescriptions}
          />
          {t("morph.ShowDescriptions")}
        </label>
      </div>
    
      {displayedAnalyses.map((analysis, index) => {
        // Add a check to ensure morphemes and descriptions exist
        const morphemes = analysis.morphemes || [];
        const descriptions = analysis.descriptions
          ? analysis.descriptions.map((desc) => desc.split(" / ").join(" / "))
          : [];

        if (morphemes.length === 0) {
          return (
            <NoDataMessage key={index} message="No morphemes available." />
          );
        }

        return (
          <div key={index}>
            <div className="table-morph">
              <div className="table-morph-row">
                <MorphemesRow
                  morphemes={morphemes}
                  descriptions={descriptions}
                  showDescriptions={showDescriptions}
                />
                
              </div>
            </div>
            {index < displayedAnalyses.length - 1 && (
              <div className="divider"></div>
            )}
          </div>
        );
      })}
      {hasMultipleAnalyses && (
        <div className="slider-controls">
          <Button
            variant="text"
            startIcon={<ChevronLeftIcon />}
            onClick={handlePrev}
            disabled={currentWordIndex === 0}
            className="slider-button"
          ></Button>
          <Tooltip
            title={!isSingleWord ? t("swipe.Hover") : ""}
            arrow
            disableHoverListener={isSingleWord}
          >
            <span>
              <SwipeIcon
                style={{ color: !isSingleWord ? "inherit" : "gray" }}
              />
            </span>
          </Tooltip>
          <Button
            variant="text"
            endIcon={<ChevronRightIcon />}
            onClick={handleNext}
            disabled={currentWordIndex === analyses.length - 1}
            className="slider-button"
          ></Button>
        </div>
      )}
    </div>
  );
};

export default WordMorphology;
