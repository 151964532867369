import { useContext } from "react";
import { LangContext } from "../Contexts/LangContext";

// Function to handle reordering of items within a list
export default function DragEndTypeLangOrder(
  result, // The result object from the drag-and-drop operation
  lang1Library, // Current state of the language library list
  updateLang1Library, // Function to update the language library list
) {
  // swap items within the same Library container
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list); // Create a copy of the original list
    const [removed] = result.splice(startIndex, 1); // Remove item from start index
    result.splice(endIndex, 0, removed); // Insert removed item at end index
    return result; // Return the reordered list
  };

  // Output log indicating the change in order
  console.log(
    `TYPE - 4 - Change Order in the Language List from ${result.source.index} -> ${result.destination.index}`,
  );
  // if the source is the library1
  let tmp = reorder(
    lang1Library, // Current state of lang1Library
    result.source.index, // Start index of dragged item
    result.destination.index, // End index (destination) of dragged item
  );
  updateLang1Library(tmp); // Update the lang1Library state with the reordered list
  return <div>Test</div>; // Placeholder return for testing
}
