import React from "react";
import { HelmetProvider } from 'react-helmet-async';
import LangContextProvider from "./Components/Contexts/LangContext";
import MainContainerFct from "./Components/Meanings/MainContainerFct";
import ModalContextProvider from "./Components/Contexts/ModalContext";
import UserContextProvider from "./Components/Contexts/UserContext";
import WordContextProvider from "./Components/Contexts/WordContext";
import { useEffect } from "react";
import { useState } from "react";
import MenuBar from "./Components/MenuBar";
import { TutorialProvider } from "./Components/Common/TutorialContext";
import { useTranslation } from "react-i18next";

const helmetContext = {};

function AppFct() {
  const [currentPage, setCurrentPage] = useState("home");

  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  return (
    <HelmetProvider context={helmetContext}>
      {" "}
      {/* Wrap with HelmetProvider */}
      <UserContextProvider>
        <WordContextProvider>
          <ModalContextProvider>
            <LangContextProvider>
              <TutorialProvider>
                <MenuBar setCurrentPage={setCurrentPage} />
                {currentPage === "home" && <MainContainerFct />}
              </TutorialProvider>
            </LangContextProvider>
          </ModalContextProvider>
        </WordContextProvider>
      </UserContextProvider>
    </HelmetProvider>
  );
}

export default AppFct;
