import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import StarBorderPurple500Icon from "@mui/icons-material/StarBorderPurple500";
import LoginIcon from "@mui/icons-material/Login";
import NewLoginForm from "./User/NewLoginForm";
import "../styles/new.css";
import { useTranslation } from "react-i18next";
import AppIcon from "../Images/turkish-flag-7_mid.png";

const MenuBar = () => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loginAnchorEl, setLoginAnchorEl] = useState(null);

  // Word List menu handlers
  const handleWordListClick = (event) => {
    setAnchorEl(event.currentTarget); // Opens the dropdown menu
  };

  const handleWordListClose = () => {
    setAnchorEl(null); // Closes the dropdown menu
  };

  // Login menu handlers
  const handleLoginClick = (event) => {
    setLoginAnchorEl(event.currentTarget);
  };

  const handleLoginClose = () => {
    setLoginAnchorEl(null);
  };

  return (
    <AppBar
      position="static"
      style={{ background: "#467696", color: "#2C3B64" }}
    >
      <Toolbar variant="dense">
        <Typography variant="h3" style={{ flexGrow: 1 }}>
          <Button
            onClick={() => (window.location.href = "/")}
            sx={{
              color: "#E0E0E0",
              fontWeight: "bold", // Make the text bold
              fontSize: "1.1rem", // Make the text larger
              fontFamily: "'Nunito', sans-serif",
              textShadow:
                "0 0 10px rgba(255, 255, 0, 0.8), 0 0 20px rgba(255, 255, 0, 0.6), 0 0 30px rgba(255, 255, 0, 0.4)",
            }}
          >
            <h1 style={{ fontSize: "16px" }}> {t("app.name")}</h1>
            <div style={{ marginLeft: "1em" }}>
              <img src={AppIcon} alt="AppIcon" width="40em" height="43em" />
            </div>
          </Button>
        </Typography>

        {/* Word Lists Button with Dropdown */}
        <Button
          sx={{
            color: "#E0E0E0",
            fontWeight: "bold",
            fontFamily: "'Nunito', sans-serif",
            textShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
          }}
          aria-controls="word-lists-menu"
          aria-haspopup="true"
          onClick={handleWordListClick}
        >
          <div className="flex-row">
            <StarBorderPurple500Icon />
            {t("menu1.WordList")}
          </div>
        </Button>
        <Menu
          id="word-lists-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleWordListClose}
        >
          <MenuItem onClick={handleWordListClose}>
            <a
              href="/Yakin_Sozluk_Kelimeler_100.pdf"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {t("menu1.Top100")}
            </a>
          </MenuItem>
          <MenuItem onClick={handleWordListClose}>
            <a
              href="/Yakin_Sozluk_Kelimeler_200.pdf"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {t("menu1.Top200")}
            </a>
          </MenuItem>
          <MenuItem onClick={handleWordListClose}>
            <a
              href="/Yakin_Sozluk_Kelimeler_300.pdf"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {t("menu1.Top300")}
            </a>
          </MenuItem>
          <MenuItem onClick={handleWordListClose}>
            <a
              href="/Yakin_Sozluk_Kelimeler_400.pdf"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {t("menu1.Top400")}
            </a>
          </MenuItem>
          <MenuItem onClick={handleWordListClose}>
            <a
              href="/Yakin_Sozluk_Kelimeler_500.pdf"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {t("menu1.Top500")}
            </a>
          </MenuItem>
        </Menu>

        {/* Home Page Link */}
        <Button
          onClick={() => (window.location.href = "/")}
          sx={{
            color: "#E0E0E0",
            fontWeight: "bold",
            fontFamily: "'Nunito', sans-serif",
            textShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
          }}
        >
          <div className="flex-row">
            <HomeIcon />
            {t("homepage")}
          </div>
        </Button>

        {/* Login Button with Dropdown */}
        <Button
          sx={{
            color: "#E0E0E0",
            fontWeight: "bold",
            fontFamily: "'Nunito', sans-serif",
            textShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
          }}
          aria-controls="login-menu"
          aria-haspopup="true"
          onClick={handleLoginClick}
        >
          <div className="flex-row">
            <LoginIcon />
            {t("login")}
          </div>
        </Button>
        <Menu
          id="login-menu"
          anchorEl={loginAnchorEl}
          open={Boolean(loginAnchorEl)}
          onClose={handleLoginClose}
        >
          <MenuItem onClick={handleLoginClose} disableRipple>
            <NewLoginForm />
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default MenuBar;
