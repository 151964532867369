import React, { createContext, useState, useMemo } from "react";
import PropTypes from "prop-types"; //For type-checking

// Create the UserContext
export const UserContext = createContext();

// Define the UserContextProvider component
function UserContextProvider({ children, initialUser = null }) {
  const [user, setUser] = useState(initialUser);

  // Memoize the context value to optimize performance
  const value = useMemo(
    () => ({
      user,
      setUser,
    }),
    [user],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

// Define PropTypes for UserContextProvider
UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired, //children is a required prop
  initialUser: PropTypes.shape({ //initialUser is an optional prop with a specific shape- rules as follows:
    name: PropTypes.string, //name should be a string
    email: PropTypes.string, //email should be a string
    roles: PropTypes.arrayOf(PropTypes.string), //roles should be an array of strings
    accessRights: PropTypes.arrayOf(PropTypes.string), //accessRights should be an array of strings
  }),
};

export default UserContextProvider;
