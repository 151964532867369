import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import MAPIURLMaker from "../MAPIURLMaker";
import "../../styles/new.css";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import Foldable from "../Common/Foldable";
import ErrorMessage from "../Common/ErrorMessage";
import NoDataMessage from "../Common/NoDataMessage";
import getMockData from "./mockData";
import LoadingIndicator from "../Common/LoadingIndicator";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SwipeIcon from "@mui/icons-material/Swipe";
import { Tooltip, Button } from "@mui/material";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import { useSwipeable } from "react-swipeable";
import Alert from "@mui/material/Alert";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

const QuoteFct = forwardRef(({ word }, ref) => {
  const { t, i18n } = useTranslation();
  const [quoteData, setQuoteData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);
  const quotesRef = ref || useRef(null);

  const isDevMode = false; // Set this to `true` to use mock data during development, and `false` for production

  useEffect(() => {
    // Reset states before fetching new data
    setQuoteData(null);
    setIsLoading(true);
    setError(null);
    setCurrentQuoteIndex(0);

    const fetchData = async () => {
      try {
        if (isDevMode) {
          // Use mock data in development mode
          const mockResponse = getMockData(word);
          if (mockResponse) {
            setQuoteData(mockResponse);
          } else {
            setError("No quotes found in mock data");
          }
        } else {
          // Make the actual API call in production mode
          const fetchStr = MAPIURLMaker(`quotes?word=${word}`);
          const response = await axios.get(fetchStr);

          //console.log("API Response:", response.data);

          if (response && response.data) {
            setQuoteData(response.data); // Set all quotes
          } else {
            setQuoteData(null); // Clear quoteData if no data is found
          }
        }
      } catch (err) {
        if (err.response && err.response.status === 404) {
          setError(t("quote.notFound"));
        } else {
          setError(t("quote.fetchError"));
        }
        console.error("No quotes found:", err);
      } finally {
        setIsLoading(false);
      }
    };

    if (word) {
      fetchData();
    }
  }, [word, isDevMode]); // Ensure this effect runs when `word` changes
  /*
    if (isLoading) {
        return <p>Loading quote...</p>;
    }

    if (!quoteData) {
        return <p>No quotes found for this word :(</p>;
    }
*/
  // Function to highlight the searched word in the quote
  const highlightWord = (text) => {
    if (!text) return text;

    // Use regex to match text with hashtags
    const regex = /#(.*?)#/g; // Match text between hashtags
    const parts = [];
    let lastIndex = 0;

    // Split the text based on the regex
    text.replace(regex, (match, p1, offset) => {
      // Push the text before the match
      parts.push(text.substring(lastIndex, offset));
      // Push the highlighted word wrapped in a span
      parts.push(
        <span key={parts.length} className="quote-highlight">
          {p1}
        </span>,
      );
      lastIndex = offset + match.length; // Update last index
      return match; // Return the match for replacement (not used here)
    });

    // Push the remaining text after the last match
    parts.push(text.substring(lastIndex));

    return parts;
  };

  const handleNext = () => {
    if (quoteData && currentQuoteIndex < quoteData.length - 1) {
      setCurrentQuoteIndex(currentQuoteIndex + 1);
    }
  };

  const handlePrev = () => {
    if (quoteData && currentQuoteIndex > 0) {
      setCurrentQuoteIndex(currentQuoteIndex - 1);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
    trackMouse: true,
  });

  const currentQuote = quoteData ? quoteData[currentQuoteIndex] : null;

  // console.log(quoteData);
  const bookData = currentQuote ? currentQuote.books[0] : {};
  const fetchImageStr = bookData
    ? MAPIURLMaker(`images/${bookData.localTNFilename}`)
    : null;

  const multipleQuotes = quoteData && quoteData.length > 1;
  const [alert, setAlert] = useState({ message: null, severity: null });

  const markAsDisabled = async (quote) => {
    try {
      const response = await axios.post(
        MAPIURLMaker(`quotes/${quote.id}/disable`),
      );
      if (response.status === 200) {
        setAlert({ message: t("quote.reportSuccess"), severity: "success" });
      } else {
        setAlert({ message: t("quote.reportFail"), severity: "error" });
      }
    } catch (error) {
      setAlert({ message: t("quote.reportFail"), severity: "error" });
      console.error("Error reporting the quote:", error);
    }
  };

  return (
    <Foldable
      title={t("quote.Title", { word })}
      ComponentIcon={
        <div>
          <FormatQuoteIcon style={{ transform: "scaleX(-1)" }} />
          <FormatQuoteIcon />
        </div>
      }
    >
      {isLoading && <LoadingIndicator loading={isLoading} />}
      {error && <ErrorMessage message={error} />}
      {!isLoading && !error && (!quoteData || quoteData.length === 0) && (
        <NoDataMessage message={t("quote.noData")} />
      )}

      {quoteData && currentQuote && (
        <div className="results-box" ref={quotesRef}>
          {/* Quote Container */}
          <div className="quote-container" {...handlers}>
            {/* Open Book Layout - First Row */}
            <div className="open-book-layout quote-first-row">
              {/* Left Page (Thumbnail) */}
              <div className="left-page">
                {isDevMode ? (
                  <img
                    src={
                      quoteData.books[0]?.localTNFilename ||
                      t("quote.noThumbnail")
                    }
                    alt={quoteData.bookTitle || t("quote.noTitle")}
                    className="quote-thumbnail"
                  />
                ) : (
                  <img
                    src={fetchImageStr || t("quote.noThumbnail")}
                    alt={currentQuote.bookTitle || t("quote.noTitle")}
                    className="quote-thumbnail"
                  />
                )}
              </div>
              {/* Right Page (Book Title, Publisher, Year) */}
              <div className="right-page">
                <div className="book-info">
                  <h4 className="book-title">
                    {currentQuote.bookTitle || t("quote.noTitle")}
                  </h4>
                  <p className="author-name">
                    {currentQuote.author || t("quote.unknownAuthor")}
                  </p>
                  <p className="book-publisher">
                    {bookData.publisher || t("quote.noPublisher")}
                  </p>
                  <p className="book-year">{bookData.publicationYear}</p>
                </div>
              </div>
            </div>
            {/* Quote Text - Second Row */}
            <div className="quote-row">
              <p className="quote-text">{highlightWord(currentQuote.quote)}</p>
            </div>
          </div>

          {/* Slider Section - Now outside of quote-container but inside Foldable */}
          <div className="slider-section">
            {/* Report Button */}
            <div
              className="report-quote"
              style={{
                display: "flex",
                justifyContent: "flex-end", // Aligns the button to the right
                alignItems: "center", // Vertically center the button if needed
              }}
            >
              <Tooltip title={t("quote.Report")} arrow>
                <button
                  className="toggle-button"
                  onClick={() => markAsDisabled(currentQuote)}
                  style={{
                    width: "30px", // Smaller width
                    height: "30px", // Smaller height
                    borderRadius: "10%", // Make the button circular
                    backgroundColor: "#b34848",
                    color: "#fff",
                    boxShadow: "none",
                    fontSize: "16px", // Smaller text size
                    display: "flex", // Flexbox for centering
                    justifyContent: "center", // Horizontally center the icon
                    alignItems: "center", // Vertically center the icon
                  }}
                >
                  <FlagCircleIcon />
                </button>
              </Tooltip>
            </div>

            {/* Slider Navigation */}
            <div className="slider-controls">
              <Button
                variant="text"
                startIcon={<ChevronLeftIcon />}
                onClick={handlePrev}
                disabled={currentQuoteIndex === 0}
                className="slider-button"
              ></Button>

              <Tooltip
                title={multipleQuotes ? t("swipe.Hover") : ""}
                arrow
                disableHoverListener={!multipleQuotes}
              >
                <span>
                  <SwipeIcon
                    style={{ color: multipleQuotes ? "inherit" : "gray" }}
                  />
                </span>
              </Tooltip>

              <Button
                variant="text"
                startIcon={<ChevronRightIcon />}
                onClick={handleNext}
                disabled={currentQuoteIndex === quoteData.length - 1}
                className="slider-button"
              ></Button>
            </div>
          </div>
        </div>
      )}
    </Foldable>
  );
});
QuoteFct.displayName = "QuoteFct";
export default QuoteFct;
