import UserActionError from "../Common/UserActionError";
import { v4 as uuid_v4 } from "uuid";
import correctSortOrders from "./CorrectSortOrders";

export default function DragEndTypeCopyLangOrChangePocket(
  result, // The result of the drag-and-drop operation
  lang1Library, // The main language library array
  lang1Pockets, // Language pockets containing categorized items
  updateLang1Pockets, // Function to update language pockets
  libName, // Name of the library
  user, // Current user object
) {
  const { source, destination } = result; // Destructure source and destination from result object
  if (source.droppableId === libName) { 
    // source = Library, destination = oneMeaningSlot => Copy library word
    console.log("TYPE - 1 - Copy from Language List");
    let item0 = lang1Library[source.index]; 
    let item = JSON.parse(JSON.stringify(item0)); // Make a deep copy of the item from the language library
    item.update_userId = user.login_id; // Set additional properties for the new item
    const destPocket = lang1Pockets[destination.droppableId]; // Check if the destination pocket already contains the item
    if (destPocket) {
      // Destination pocket was already used, new item will be added to the existing list
      const checkExisting = destPocket.filter((tmp) => {
        // checking if this word was already in the pocket
        console.log("item =" + JSON.stringify(tmp));
        return tmp.lang_word === item.lang_word;
      });
      if (checkExisting.length > 0) {
        // if the word is already in the pocket show an error window to alert the user
        throw new UserActionError(
          'This entry ( "' +
            item.lang_word +
            '" ) is already in this pocket ( position : ' +
            checkExisting[0].sort_order +
            " )!\r\nCan't add again !",
        );
      }
    }

    // Extract chapter_id and meaning_id from the destination droppableId
    let parts = destination.droppableId.split("-");
    let chapter_id = parseInt(parts[0], 10);
    let meaning_id = parseInt(parts[1], 10);
    
    // Create a new item object with UUID and updated properties
    const newItem = {
      id: uuid_v4(),
      chapter_id: chapter_id,
      meaning_id: meaning_id,
      owner: user.login_id,
      lang_word: item.lang_word,
      sort_order: result.destination.index + 1, 
    };

    let destItems = [];
    if (destPocket) {
      destItems = [...destPocket];
      destItems.splice(destination.index, 0, newItem); // Insert new item at the specified index
    } else {
      destItems = [newItem]; // If destination pocket is empty, set it with the new item
    }

    // Update lang1Pockets with the new pocket items
    const tmpPockets = {
      ...lang1Pockets,
      [destination.droppableId]: destItems,
    };

    updateLang1Pockets(tmpPockets); // Call the updateLang1Pockets function with updated pockets
  } else {
    // moving between meaning pockets,dropped in a different container

    const sourcePocket = lang1Pockets[source.droppableId];
    const destPocket = lang1Pockets[destination.droppableId] || [];
    console.log(
      `TYPE - 2 - Move from pocket ${source.droppableId} to another pocket ${destination.droppableId}`,
    );
    try {
      const sourceItems = [...sourcePocket];
      const destItems = [...destPocket];
      const [removed] = sourceItems.splice(source.index, 1); // Remove item from source pocket

      // change the chapter and meaning ids to adopt
      var parts = destination.droppableId.split("-");
      const newChapterId = parts[0];
      const newMeaningId = parts[1];
      removed.chapter_id = newChapterId;
      removed.meaning_id = newMeaningId;

      //put it into the destItems
      destItems.splice(destination.index, 0, removed);

      // update sort orders

      const newSourceItems = correctSortOrders(sourceItems);
      const newDestItems = correctSortOrders(destItems);

      // Update lang1Pockets with the updated source and destination items
      const tmpPockets = {
        ...lang1Pockets,
        [source.droppableId]: newSourceItems,
        [destination.droppableId]: newDestItems,
      };
      updateLang1Pockets(tmpPockets); // Call the updateLang1Pockets function with updated pockets
    } catch (e) {
      console.log(e); // Log any errors that occur during the process
    }
  }
  return <div>Test</div>; // Return a test div
}
