import React, { useState, useEffect } from "react";
import arrowImage from "../../Images/arrowImage.png";
import { useTranslation } from "react-i18next";

const Tutorial = ({
  refElement,
  step,
  currentStep,
  message,
  onNext,
  onClose,
}) => {
  const { t, i18n } = useTranslation();
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const [popupWidth, setPopupWidth] = useState(0);
  const [dontShowAgain, setDontShowAgain] = useState(false);

  useEffect(() => {
    //Reset tutorial for testing (only during development)
    /* eslint-disable no-undef */
    /* if (process.env.NODE_ENV === "development") {
      localStorage.removeItem("tutorialClosed");
    } */
    /* eslint-enable no-undef */

    if (currentStep === step && refElement.current) {
      const elementRect = refElement.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const viewportWith = window.innerWidth;
      const popupMaxWidth = 350;

      let leftPosition = elementRect.left + elementRect.width / 2 + 200;

      //Ensure the popup doesn't go off the sides of the viewport
      if (leftPosition - popupMaxWidth / 2 < 0) {
        leftPosition = popupMaxWidth / 2;
      } else if (leftPosition + popupMaxWidth / 2 > viewportWith) {
        leftPosition = viewportWith - popupMaxWidth / 2;
      }

      setPopupPosition({
        top: elementRect.top + window.scrollY + -100,
        left: leftPosition,
      });

      // If the target element is off-screen, scroll to it
      const elementTop = elementRect.top + window.scrollY;
      const elementBottom = elementRect.bottom + window.scrollY;

      if (
        elementBottom > window.scrollY + viewportHeight ||
        elementTop < window.scrollY
      ) {
        refElement.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [currentStep, refElement, step]);

  const handleDontShowAgainChange = (e) => {
    setDontShowAgain(e.target.checked);
  };

  const handleClose = () => {
    if (dontShowAgain) {
      localStorage.setItem("tutorialClosed", "true");
    }
    onClose(); //Notify the parent to close the tutorial
  };

  if (currentStep !== step || !refElement.current) return null;

  return (
    <div
      className="tutorial-popup"
      style={{
        position: "absolute",
        top: popupPosition.top,
        left: popupPosition.left,
        transform: "translateX(-50%)",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        padding: "15px",
        border: "1px solid rgba(0, 0, 0, 0.2)",
        borderRadius: "15px",
        maxWidth: `250px`,
        wordWrap: "break-word",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <p
        className="legend"
        style={{
          fontSize: "16px",
          fontWeight: "500",
          textAlign: "center",
          color: "#333",
        }}
      >
        {message}
      </p>
      <div
        className="flex-row"
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          gap: "10px",
        }}
      >
        <button
          className="toggle-button"
          onClick={onNext}
          style={{
            transition: "background-color 0.3s",
          }}
        >
          Next
        </button>
        <button
          className="toggle-button"
          onClick={handleClose}
          style={{
            transition: "background-color 0.3s",
            boxShadow: "1px 1px 2px 2px rgba(179, 72, 72, 0.50)",
          }}
        >
          Close
        </button>
      </div>
      <div
        className="flex-row"
        style={{ display: "flex", alignItems: "center", gap: "8px" }}
      >
        <input
          type="checkbox"
          id="dontShowAgain"
          checked={dontShowAgain}
          onChange={handleDontShowAgainChange}
          style={{ width: "18px", height: "18px", cursor: "pointer" }}
        />
        <label
          className="btn-disabled"
          htmlFor="dontShowAgain"
          style={{ fontSize: "14px", color: "#555", cursor: "pointer" }}
        >
          {t("tutorial.DontShow")}
        </label>
      </div>
      {/* Arrow pointing to the target element */}
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "-25px",
          transform: "translateY(-60%) rotate(-10deg)",
        }}
      >
        <img
          src={arrowImage} // The path to the PNG arrow
          alt="Arrow pointing to target"
          style={{
            width: "50px", // Adjust the size of the arrow if needed
            height: "50px",
          }}
        />
      </div>
    </div>
  );
};

export default Tutorial;