/* createContext: React function used to create a React context,
useState: React hook to allow functional components to have state variables,
useMemo: React hook that allows to cache a value
*/
import { createContext, useState, useMemo } from "react";

export const WordContext = createContext(""); //a context is created with the initial value of an empty string and exported to be used in other parts of the application

//Define a context provider component
function WordContextProvider(props) {
  const [word, setWord] = useState(""); //Create a state variable "word" with the initial value of an empty string

  const value = useMemo( //Memoize the context value 
    () => ({
      word, //curent state of "word"
      setWord, //function to update "word"
    }),
    [word], //Dependencies array to recompute value only when "word" changes
  );

  return (
    <WordContext.Provider value={value}>{props.children}</WordContext.Provider> //Provide the context value to children components
  );
}
export default WordContextProvider; //Export the provider component as the default export
