import React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";
import AppFct from "./AppFct";
import reportWebVitals from "./reportWebVitals";
import "./i18n"; // Import i18n configuration


const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrateRoot(rootElement, (
    <React.StrictMode>
      <AppFct />
    </React.StrictMode>
  ));
} else {
  createRoot(rootElement).render(
    <React.StrictMode>
      <AppFct />
    </React.StrictMode>
  );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
