import React, { Component } from "react";
import PropTypes from "prop-types";
import Tab from "./Tab";

// Manages and displays tabbed content, allowing users to switch between different tabs
class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired, // Define propTypes for type-checking
  };

  constructor(props) {
    super(props); // Call the parent constructor with props
    if (this.props.children.length > 0) {
      this.state = {
        activeTab: this.props.children[0].props.label, // Set the first tab as active
        isLoaded: true, // Indicate that tabs are loaded
      };
    } else {
      this.state = { isLoaded: false }; // no yakins found
    }
  }

  onClickTabItem = (tab) => {
    this.setState({ activeTab: tab }); // Update the active tab when a tab is clicked
  };

  render() {
    if (this.state.isLoaded === true) {
      const {
        onClickTabItem,
        props: { children },
        state: { activeTab },
      } = this;

      return (
        <div className="tabs">
          {/* <ol className="tab-list">
            {children.map((child) => {
              const { label } = child.props;

              return (
                <Tab
                  activeTab={activeTab} // Pass the active tab
                  key={label} // Use the label as the key
                  label={label} // Pass the label
                  onClick={onClickTabItem} // Pass the click handler
                />
              );
            })}
          </ol> */}
          <div
            className="tab-content"
            style={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
            }}
          >
            {children.map((child) => {
              if (child.props.label !== activeTab) return undefined; // Render only the active tab content
              return child.props.children;
            })}
          </div>
        </div>
      );
    } else {
      return <div>No yakıns found !</div>; // Display message if no tabs are found
    }
  }
}

export default Tabs;
