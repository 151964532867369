import React, { useContext, useState } from "react";
import { LangContext } from "../Contexts/LangContext";
import { UserContext } from "../Contexts/UserContext";
import Delete from "@mui/icons-material/DeleteForeverOutlined";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { v4 as uuid_v4 } from "uuid";
import correctSortOrders from "../DragFunctions/CorrectSortOrders";

/* 
This component manages and displays language pockets associated with specific chapters and meanings, 
allowing users with appropriate permissions to edit and reorder these pockets through drag-and-drop functionality
*/
async function deleteItem(
  prefix,
  index,
  lang1Pockets,
  updateLang1Pockets,
  jwt_token,
) {
  let targetRow = lang1Pockets[prefix]; // Access the specific pocket array using the prefix
  const removedItem = targetRow.splice(index, 1); // Remove an item from the pocket array at the specified index

  const updatedSortOrderList = correctSortOrders(targetRow); // Update the sort order of items in the pocket array

  const newPockets = {
    ...lang1Pockets,
  };

  if (updatedSortOrderList.length > 0) {
    newPockets[prefix] = updatedSortOrderList; // Update the lang1Pockets state with the new order list for the specific prefix
  } else {
    delete newPockets[prefix]; // Delete the prefix from lang1Pockets if the updated list is empty
  }

  updateLang1Pockets(newPockets); // Update the context state with the new lang1Pockets object
}

function MeaningLangsContainer({ chapter_id, meaning_id, hideLangPockets }) {
  const { lang1Pockets, updateLang1Pockets } = useContext(LangContext); // Access language pockets state and update function from context
  const { user } = useContext(UserContext); // Access user context to determine editing rights

  const AI_CAN_EDIT_MEANINGLANGS_EN = user?.roles?.includes(
    "AI_CAN_EDIT_MEANINGLANGS_EN",
  ); // Check if the user has editing rights

  let myPockets = [];
  let prefix = `${chapter_id}-${meaning_id}`; // Access specific pockets for a chapter and meaning ID, default to an empty array if not found

  myPockets = lang1Pockets[prefix];
  if (!myPockets) {
    myPockets = [];
  } // Initialize myPockets as an empty array if no pockets are found

  // Don't render the droppable container if hideLangPockets is true
  if (hideLangPockets) return null;

  return (
      <Droppable
        droppableId={prefix}
        key={prefix}
        direction="horizontal"
        isDropDisabled={!AI_CAN_EDIT_MEANINGLANGS_EN} // Disabling drop if user cannot edit
        style={{ display: "flex", flexWrap: "wrap" }}
      >
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="meaning-lang1-cont flex-row"
            style={{
              backgroundColor: snapshot.isDraggingOver ? "#b34848" : "#F8F6F7",
            }}
          >
            {myPockets.map((item, index) => {
              const newId = `${prefix}#${item.lang_word}`;
              return (
                <Draggable
                  key={newId}
                  draggableId={newId}
                  index={index}
                  isDragDisabled={!AI_CAN_EDIT_MEANINGLANGS_EN} // Disabling drag if user cannot edit
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="lang-pocket"
                      style={{
                        backgroundColor: snapshot.isDragging
                          ? "#D4AC0D"
                          : "#F8F6F7",
                        ...provided.draggableProps.style,
                        flexWrap: "wrap",
                        flexGrow: 0.2,
                        paddingLeft: "14px",
                        alignItems: "center",
                      }}
                    >
                      <div className="lang-word">{item.lang_word}</div>
                      <div style={{ flexGrow: 0.1 }} />
                      <div className="author">{item.owner}</div>
                      <div style={{ flexGrow: 2 }} />
                      {AI_CAN_EDIT_MEANINGLANGS_EN && ( // Render delete icon only if user can edit
                        <Delete
                          className="deleteIcon"
                          onClick={() => {
                            deleteItem(
                              prefix,
                              index,
                              lang1Pockets,
                              updateLang1Pockets,
                              user.jwt_token,
                            );
                          }}
                        />
                      )}
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
  );
}

export default MeaningLangsContainer;
