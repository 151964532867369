import React, { useState } from "react";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

const Foldable = ({ title, ComponentIcon, children }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="foldable-container">
      <div
        className="title flex-row comp-header"
        onClick={toggleExpand}
        style={{ flexDirection: "column" }}
      >
        {/* First row: Title and ComponentIcon */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <span className="fct-header">{title}</span>
          <div style={{ padding: "0.4em" }}>{ComponentIcon}</div>
        </div>

        {/* Second row: Fold/Unfold icon centered */}
        <div style={{ textAlign: "center", width: "100%" }}>
          {isExpanded ? (
            <div className="switch">
              <KeyboardDoubleArrowUpIcon
                style={{
                  transform: "scaleX(1)", // This scales the icon 1.5 times wider along the x-axis
                }}
              />
            </div>
          ) : (
            <div className="switch">
              <KeyboardDoubleArrowDownIcon
                style={{
                  transform: "scaleX(1)", // This scales the icon 1.5 times wider along the x-axis
                }}
              />
            </div>
          )}
        </div>
      </div>

      {isExpanded && <div className="foldable-content">{children}</div>}
    </div>
  );
};

export default Foldable;
