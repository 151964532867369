import { Component } from "react";
import { createContext, useMemo, useState } from "react";

export const ModalContext = createContext(); //Create a context for modal dialogs

export class ModalDialog extends Component { //Define a modal dialog component class
  onClose = (e) => { //Method to handle closing the modal
    this.props.onClose && this.props.onClose(e);
  };
  render() { //Render method for the ModalDialog component
    if (!this.props.show) {
      return null; //If modal is not shown, render nothing
    }
    return ( //Render the modal structure
      <div className="modal" id="modal">
        <h2>Modal Window</h2>
        <div className="content">{this.props.children}</div>
        <div className="actions">
          <button className="toggle-button" onClick={this.onClose}>
            close
          </button>
        </div>
      </div>
    );
  }
}
const modalDialog = new ModalDialog(); //Create an instance of the ModalDialog class

function ModalContextProvider(props) { //Define a context provider component
  const [modalComponent, showModalComponent] = useState(modalDialog); //Create state for the modalComponent- initially set to modalDialog instance

  const value = useMemo( //Memoize the context value
    () => ({
      modalComponent, //current modal component
      showModalComponent, //function to update the modal component
    }),
    [modalComponent], //Dependencies array to recompute value only when modalComponent changes
  );

  return ( //Return the context provider with the memoized value
    <ModalContext.Provider value={value}>
      {props.children}
    </ModalContext.Provider>
  );
}
export default ModalContextProvider; //Export the provider as a default export
