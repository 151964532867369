import React, { Component } from "react";

import Isim from "@mui/icons-material/LocalFlorist";
import Fiil from "@mui/icons-material/OpenWith";
import Sıfat from "@mui/icons-material/Description";
import Zarf from "@mui/icons-material/Mail";

import Empty from "@mui/icons-material/CheckBoxOutlineBlank";

// Renders different icons based on the wordType prop it receives
class WordTypeIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: props,
    };
  }
  render() { // Check if values are undefined, render nothing if true
    if (typeof this.state.values === "undefined") {
      return <div></div>;
    } else {
      let currType = this.state.values.wordType; // Get the current word type from props
      switch (currType) {
        case "isim":
          return <Isim fontSize="small" />; // Render Isim icon for noun
        case "fiil":
          return <Fiil fontSize="small" />; // Render Fiil icon for verb
        case "sıfat":
          return <Sıfat fontSize="small" />; // Render Sıfat icon for adjective
        case "zarf":
          return <Zarf fontSize="small" />; // Render Zarf icon for adverb
        default:
          return <Empty fontSize="small" />; // Render Empty icon if type is not recognized
      }
    }
  }
}

export default WordTypeIcon;
