import React, { createContext, useState, useEffect } from 'react';

export const TutorialContext = createContext();
const compArray = ["FullWord", "SupportingData", "HistoryColumn"];
const stepsArray = [6, 2, 1];

export const TutorialProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [currentFocus, setCurrentFocus] = useState(0); // Integer identifier of the currentFocus component
  const [currentFocusName, setCurrentFocusName] = useState(
    compArray[currentFocus],
  ); // String identifier of the currentFocus component
  const [tutorialVisible, setTutorialVisible] = useState(true);

  // Check if the tutorial was closed before
  useEffect(() => {
    const isTutorialClosed = localStorage.getItem("tutorialClosed");
    if (isTutorialClosed === "true") {
      setTutorialVisible(false); // Hide the tutorial if it was closed before
    }
  }, []);

  const handleNextStep = () => {
    let updatedStep = currentStep + 1; // Local variable to track step

    if (updatedStep > stepsArray[currentFocus]) {
      // Step exceeds the current component's step count
      if (currentFocus < compArray.length - 1) {
        // If there are more components left to show in the tutorial
        setCurrentFocus(currentFocus + 1); // Move to the next component
        setCurrentFocusName(compArray[currentFocus + 1]); // Update the focus name
        updatedStep = 1; // Reset step for the new component
      } else {
        // If no more components, close the tutorial
        handleCloseTutorial();
        return; // Exit to prevent further updates
      }
    }

    // Update the state only after all logic is completed
    setCurrentStep(updatedStep);   
  };

  const handleCloseTutorial = () => {
    setTutorialVisible(false);
    localStorage.setItem("tutorialClosed", "true");
  };

  return (
    <TutorialContext.Provider
      value={{
        currentStep,
        currentFocus,
        currentFocusName,
        tutorialVisible,
        handleNextStep,
        handleCloseTutorial,
      }}
    >
      {children}
    </TutorialContext.Provider>
  );
};
