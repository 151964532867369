import React from "react";

/* This component displays a clickable item for search results.
 It adjusts the background color based on a calculated HSL value from the distance prop and
  allows clicking to trigger a search with the displayed word.  */
const SearchResultItem = (props) => {
  // Check if props are undefined, render "Nothing" if true
  if (typeof props === "undefined") {
    return <div>Nothing</div>;
  } else {
    const { word, distance, setSearchWord } = props; // Destructure props to get word, distance, and setSearchWord function
    //console.log("word:", word, "distance:", distance);
    let index100 = distance * 100; // Calculate index value based on distance
    let hslValue = `hsl(46, ${Math.min(index100, 89)}%, 66%, 0.70)`; // Create HSL color value based on index100
    return (
      <div
        className="small-box quick-access-item"
        style={{
          backgroundColor: hslValue, // Set background color dynamically based on hslValue
        }}
        title={word} // Show word as title on hover
        onClick={() => setSearchWord(word)} // Call setSearchWord function with word onClick
      >
        {word} {/* Display the word */}
      </div>
    );
  }
};

export default SearchResultItem;
